import clsx from "clsx";
import dynamic from "next/dynamic";
import { ComponentProps, ComponentType, ReactNode, useState } from "react";

import SVAcademyLogo from "components/atoms/SVAcademyLogo";
import Typography from "components/atoms/Typography";
import ApplyStepOneForm from "components/organisms/ApplyStepOneForm";
import BrochureForm from "components/organisms/BrochureForm";
import EmployersForm from "components/organisms/EmployersForm";
import SimpleLeadForm from "components/organisms/SimpleLeadForm";

import { getHexFromColorName } from "lib/tailwind";

import { BackgroundColors, TextColors } from "types/theme.types";

export type AcceptedFormTypes =
  | "applyStepOne"
  | "employers"
  | "brochure"
  | "simpleLead";

type HeroProps = {
  titleColor?: TextColors;
  bg: BackgroundColors;
  formBg: BackgroundColors;
  formSubmittedBg: BackgroundColors;
  subtitleColor?: TextColors;
  showLogo?: boolean;
  logoColor?: TextColors;
  subtitle?: string;
  title?: string;
  children?: ReactNode;
  form: AcceptedFormTypes;
  formProps?: ComponentProps<typeof ApplyStepOneForm | typeof SimpleLeadForm>;
  showLearnMoreIcon?: boolean;
  heroClassName?: string;
};

const dynamicComponents: Record<AcceptedFormTypes, ComponentType<unknown>> = {
  applyStepOne: dynamic(() => import("components/organisms/ApplyStepOneForm")),
  brochure: dynamic(() => import("components/organisms/BrochureForm")),
  employers: dynamic(() => import("components/organisms/EmployersForm")),
  simpleLead: dynamic(() => import("components/organisms/SimpleLeadForm")),
};

export default function HeroWithForm(props: HeroProps) {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const formProps:
    | Record<"applyStepOne", ComponentProps<typeof ApplyStepOneForm>>
    | Record<"employers", ComponentProps<typeof EmployersForm>>
    | Record<"brochure", ComponentProps<typeof BrochureForm>>
    | Record<"simpleLead", ComponentProps<typeof SimpleLeadForm>> = {
    applyStepOne: props.formProps ?? {
      leadContext: "Apply Landing",
      continueApplicationLink: "/apply",
      nextStepRedirectPath: "/apply-now/thank-you",
      flow: "unified",
    },
    employers: {
      submitted: formSubmitted,
      setFormSubmitted,
    },
    brochure: {
      leadContext: "Brochure Form",
      nextStepRedirectPath: "/brochure/thank-you",
      continueApplicationLink: "/apply",
      flow: "unified",
    },
    simpleLead: {
      leadContext: "Simple Lead Form",
      nextStepRedirectPath: "/simple-lead/thank-you",
      continueApplicationLink: "/apply",
      flow: "unified",
      ...props.formProps,
    },
  };

  const getForm = (key: AcceptedFormTypes) => {
    const FormComponent = dynamicComponents[key];
    const componentProps = formProps[key];
    return <FormComponent {...(componentProps as Record<string, unknown>)} />;
  };

  const logoColorHex = getHexFromColorName(props.logoColor);

  const titleClassName = clsx(
    "text-center",
    "mb-8",
    "md:text-left",
    props.titleColor
  );

  const heroClassName = clsx(
    "grid",
    "grid-cols-2",
    "relative",
    "pt-28",
    "pb-0",
    "md:pb-16",
    props.bg,
    props.heroClassName
  );

  const formContainerClassName = clsx(
    "md:p-10",
    "p-6",
    "rounded-[1.5rem]",
    "md:mr-16",
    "lg:mr-24",
    "xl:mr-48",
    "md:max-w-xl",
    "m-4",
    "md:m-0",
    formSubmitted ? props.formSubmittedBg : props.formBg
  );

  const ReadMoreDown = props.showLearnMoreIcon
    ? dynamic(() => import("components/atoms/ReadMoreDown"))
    : null;

  return (
    <div className={heroClassName}>
      <section className="col-span-2 md:col-span-1 w-full flex justify-center h-fit mb-10">
        <div className="flex justify-center md:justify-end p-0 mx-4 w-full bg-transparent">
          <div className="max-w-lg md:px-12 lg:px-0 md:pr-6">
            {props.showLogo && (
              <div className="flex justify-center md:justify-start pb-6">
                <SVAcademyLogo fill={logoColorHex} width={150} />
              </div>
            )}
            {!props.children && (
              <Typography.H1 className={titleClassName}>
                {props.title}
              </Typography.H1>
            )}
            {!props.children && (
              <Typography.BodyLg
                className={clsx(
                  "text-center",
                  "md:text-left",
                  props.subtitleColor
                )}
              >
                {props.subtitle}
              </Typography.BodyLg>
            )}
            {props.children}
          </div>
        </div>
      </section>

      <div className="col-span-2 md:col-span-1">
        <div className={formContainerClassName}>{getForm(props.form)}</div>
      </div>
      {props.showLearnMoreIcon && (
        <div className="absolute hidden -bottom-[3.75rem] w-full justify-center z-1 md:flex">
          <ReadMoreDown />
        </div>
      )}
    </div>
  );
}
